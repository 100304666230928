<template>
  <main class="container-fluid">
    <header class="page-header">
      <h1 class="page-title">ABM</h1>
      <p class="page-description">{{$t('title')}}</p>
    </header>
    <form @submit.prevent="search">
      <div class="form-group">
        <span class="icon">
          <fa icon="search"/>
        </span>
        <input
          type="text"
          v-model="query"
          class="form-control"
          :class="[searchType]"
          :placeholder="$t('leadenhancer.placeholder')"
        />
        <i v-if="query.length > 0" class="search-type text-muted" v-text="$t(`leadenhancer.${searchType}`)"/>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group all-countries">
            <label class="switch switch-sm">
              <input type="checkbox"
                     id="allCountries"
                     :value="true"
                     v-model="countries">
              <div class="slider round" :class="{checked: countries}"/>
            </label>
            <label for="allCountries" class="text-muted" v-text="$t('leadenhancer.includeCountries')"/>
          </div>
        </div>
        <div class="col-md-6 text-right" v-if="results.length">
          <p v-if="total === results.length" v-text="$t('leadenhancer.searchResults', {length: results.length})"/>
          <p v-if="results.length < total"
             v-html="$t('leadenhancer.searchResultsSpecify', {total, length: results.length})"/>
        </div>
      </div>
    </form>

    <section class="leads" :class="{'has-selected-leads': selectedLeads.length}">
      <div class="leads__wrapper">
        <section class="le-results" v-if="results.length && !loading">
          <result-card v-for="(result, index) in filteredResults"
                       :key="index"
                       :result="result"
                       v-if="!isSelected(result)"
                       :selected="isSelected(result)"
                       @select="appendLead(result)"
          />
        </section>
        <section v-else-if="message" class="no-results" v-text="message"/>
      </div>
      <aside class="selected-leads" v-if="selectedLeads.length">
        <header>
          <h5 v-text="$t('leadenhancer.selectedTitle')"/>
          <div>
            <button v-clipboard="selectedAsCommaCSV"
                    v-tooltip="$t('leadenhancer.copySelected')">
              <fa icon="copy"/>
            </button>

            <button
              @click.prevent="mailSelected"
              v-tooltip="$t('leadenhancer.mailSelected')">
              <fa icon="paper-plane"/>
            </button>

            <button v-tooltip="$t('leadenhancer.printSelected')"
                    @click.prevent="printSelected"
            >
              <fa icon="print"/>
            </button>
          </div>
        </header>
        <section>
          <result-card v-for="(result, index) in selectedLeads"
                       :key="index"
                       :result="result"
                       :selected="isSelected(result)"
                       @select="appendLead(result)"
          />
        </section>
      </aside>
    </section>
    <logo-loader v-if="!results.length && loading"/>
  </main>
</template>

<script>
  import {mapActions} from 'vuex'
  import print from 'print-js'
  import ResultCard from './components/LeadEnhancerResultCard'

  export default {
    name: 'LeadEnhancer',
    components: {ResultCard},
    data: () => ({
      results: [],
      total: 0,
      query: '',
      timeout: null,
      loading: false,
      message: '',
      page: 0,
      countries: false,
      selectedLeads: []
    }),
    watch: {
      query: 'search',
      countries: 'search'
    },
    computed: {
      filteredResults() {
        return this.results
      },
      searchType() {
        return isNaN(this.query) ? "textSearch" : "branchSearch"
      },
      selectedAsCommaCSV() {
        return this.selectedLeads.map(item => item.leId).join(',')
      }
    },
    methods: {
      ...mapActions('leadenhancer', ['fetchLeads', 'mailLeads']),
      appendLead(lead) {
        if (this.isSelected(lead)) {
          this.selectedLeads = this.selectedLeads.filter(item => item.leId !== lead.leId)
        } else {
          this.selectedLeads.push(lead)
        }

      },
      isSelected(lead) {
        return this.selectedLeads.filter(item => item.leId === lead.leId).length > 0
      },
      async mailSelected() {
        if (this.selectedLeads.length) {
          await this.mailLeads(this.selectedAsCommaCSV)
          this.$swal('Success!', 'Email med lead enhancer id\'er afsendt.', 'success')
        }
      },
      printSelected() {
        if (this.selectedLeads.length) {

          const printable = []

          this.selectedLeads.forEach(lead => {
            printable.push({
              name: lead.name + (lead.address.countryISO ? ` - <b>${lead.address.countryISO.toLowerCase()}</b>` : ''),
              leId: lead.leId || ''
            })
          })

          const properties = [
            {field: 'name', displayName: 'Firma'},
            {field: 'leId', displayName: 'Lead enhancer ID'}
          ]

          const header = '<h3 class="custom-h3">Lead Enhancer udtræk fra SpotonLive</h3>'

          const style = `
            .custom-h3 {
              color: #12374c;
              font-family: 'Open Sans';
            }
          `

          const shared = `
            font-size: 12px;
            text-align: left;
            font-family: 'Open Sans';
            padding: 5px;
          `

          const gridHeaderStyle = `
            border: 1px solid #cccccc;
            font-weight: bold;
            ${shared}
          `

          const gridStyle = `
            border: 1px solid #cccccc;
            ${shared}
          `

          print({
            printable,
            properties,
            header,
            style,
            gridHeaderStyle,
            gridStyle,
            type: 'json',
            documentTitle: 'SpotonLive ABM'
          })
        }
      },
      search() {
        clearTimeout(this.timeout)
        this.message = ''
        if (this.query.length >= 3) {
          this.loading = true
          this.timeout = setTimeout(async () => {
            const {results, total} = await this.fetchLeads({
              query: this.query,
              countries: this.countries
            })

            clearTimeout(this.timeout)
            if (total === 0) {
              this.message = $t('leadenhancer.noResults')
            } else {
              this.results = results
              this.total = total
            }
            this.loading = false
          }, 500)
        } else {
          this.results = []
          this.total = 0
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/assets/scss/variables/colors";

  .all-countries {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .text-muted {
      font-size: 90%;
      font-weight: 400;
      margin-left: 8px;
      margin-top: -2px;
      cursor: pointer;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    &.switch-sm {
      width: 50px;
      height: 25px;

      .slider:before {
        width: 17px;
        height: 17px;
      }
    }

    &.switch-xs {
      width: 46px;
      height: 20px;

      .slider:before {
        width: 12px;
        height: 12px;
      }
    }
  }

  /* Hide default HTML checkbox */
  .switch input {
    display: none;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: $sgme-primary;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $sgme-primary;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  form {
    width: 100%;
    margin: 40px auto 60px;

    @media screen and (min-width: 1300px) {
      max-width: 900px;
    }

    .form-group {
      position: relative;

      .form-control {
        padding: 8px 90px 8px 40px;


        &.branchSearch {
          padding-right: 115px;
        }
      }

      .search-type {
        position: absolute;
        right: 15px;
        top: 12px;
        font-size: 12px;
      }


      .icon {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #e3e3e3;
      }
    }
  }

  .le-results {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    .result-card {
      max-width: 580px;
    }
  }

  .selected-leads {
    grid-area: selected;

    > section .result-card + .result-card {
      margin-top: 25px;
    }

    @media screen and (max-width: 767px) {
      > section {
        display: grid;
        gap: 25px;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      }
    }

    header {
      margin-bottom: 5px;
      display: grid;
      grid-template-columns: 1fr auto;

      @media screen and (min-width: 768px) {
        margin-top: -40px;
      }

      h5 {
        margin: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 36px;
      }

      button {
        border: 0;
        outline: 0;
        background: 0;
        padding: 8px;
        color: $gray-600;

        &:hover {
          color: #333333;
        }
      }
    }
  }

  .leads {
    display: grid;
    gap: 25px;
    margin-bottom: 100px;
    grid-template-columns: 1fr;
    grid-template-areas: "search";

    @media screen and (min-width: 768px) {
      grid-template-areas: "search search";
      grid-template-columns: 1fr 370px;
    }

    @media screen and (min-width: 1300px) {
      max-width: 1200px;
      margin: 0 auto 100px;
    }

    &.has-selected-leads {
      grid-template-areas: "search" "selected";

      @media screen and (min-width: 768px) {
        grid-template-areas: "search selected";
      }

      @media screen and (min-width: 1300px) {
        max-width: none;
      }

      .leads__wrapper {
        &::before {
          display: block;
          position: absolute;
          content: "";
          top: 0;
          bottom: 0;
          right: -12px;
          width: 1px;
          background: $gray-400
        }
      }
    }

    &__wrapper {
      grid-area: search;
      position: relative;
    }
  }
</style>
