<template>
  <section class="result-card">
    <h2 v-if="result.name">{{result.name}}</h2>
    <p class="last-updated" v-if="lastUpdated">{{$t('leadenhancer.lastUpdated')}} {{lastUpdated}}</p>
    <div class="employees" v-if="result.noOfEmployees">
      <fa icon="users"/>
      <span v-text="result.noOfEmployees"/>
    </div>

    <div class="metablock">
      <div class="location " v-if="result.address">
        <div class="flag">
          <flag :iso="result.address.countryISO.toLowerCase()" :squared="false"/>
        </div>
        <div>
          <span class="address" v-if="result.address.address" v-text="result.address.address"/>
          <span v-if="result.address.postalCode" class="zip-code" v-text="result.address.postalCode"/>
          <span v-if="result.address.city" class="city" v-text="result.address.city"/>
        </div>
      </div>
      <div class="website">
        <a v-if="result.address.www"
           :href="`http://${result.address.www}/`"
           target="_blank"
           rel="noreferrer, nofollow">
          <fa class="icon" icon="globe"/>
          {{result.address.www}}
        </a>
      </div>
    </div>
    <div class="copy-button" v-if="result.leId || result.sniNaceCode">
      <button
        class="btn btn-primary"
        v-if="result.leId"
        v-tooltip="$t('leadenhancer.leId', {leId: result.leId})"
        v-clipboard="result.leId"
        @success="onCopied('leId')"
        @error="onCopiedError('leId')"
      >
        <b>LEID</b>
      </button>
      <button
        class="btn btn-primary"
        v-if="result.sniNaceCodeDescription && result.sniNaceCode"
        v-tooltip="$t('leadenhancer.copySni', {code: result.sniNaceCode})"
        v-clipboard="result.sniNaceCode"
        @success="onCopied('sniNaceCode')"
        @error="onCopiedError('sniNaceCode')"
      >
        <b v-text="$t('leadenhancer.copySniTitle')"/>
      </button>

      <a href="#" class="select-button" :class="{selected}" @click.prevent.stop="$emit('select')">
        <fa icon="plus" v-if="!selected"/>
        <fa icon="times" v-else />
      </a>
    </div>
  </section>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'LeadEnhancerResultCard',
    props: {
      result: {
        type: Object,
        required: true
      },
      selected: {
        type: Boolean,
        required: true
      }
    },
    data: () => ({
      isOpen: false
    }),
    computed: {
      lastUpdated() {
        if (!this.result.lastUpdatedDateTimeISO8601) return false

        return moment(this.result.lastUpdatedDateTimeISO8601).fromNow()
      }
    },
    methods: {
      onCopied(type) {
        const id = this.result[type]
        const title = this.$t(`leadenhancer.${type}Notify`)

        this.$notify({
          duration: 2000,
          group: 'notify',
          type: 'success',
          title: this.$t('leadenhancer.notifyTitle', {title}),
          text: this.$t(`leadenhancer.notifyMessage`, {id})
        })
      },
      onCopiedError() {
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/variables/colors';

  .result-card {
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid $gray-300;
    border-radius: 4px;
    position: relative;
    font-size: 13px;
    transition: all ease-in-out 100ms;
    z-index: 10;
    box-shadow: 0 5px 15px transparent;

    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      border: 1px solid $gray-100;
      z-index: 20;
      transition: all ease-in-out 250ms;
    }
  }

  h2 {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    color: $primary;
    padding-right: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .last-updated {
    color: $gray-600;
    font-size: 13px;
    font-style: italic;
  }

  .employees {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $cyan;

    span {
      color: $gray-800;
    }
  }

  .location {
    display: grid;
    grid-template-columns: 25px 1fr;

    .address {
      &:after {
        content: ', ';
      }
    }

    div:last-child span:last-child {
      margin-left: 3px;
    }
  }

  .website {
    display: block;
    margin-bottom: 0;
    margin-top: 5px;

    .icon {
      width: 17px;
      margin-right: 7px;
      text-align: left;
      color: #333333;
    }
  }

  .metablock {
    flex-grow: 1;
    height: 42px;
    margin-bottom: 15px;
  }

  button {
    display: block;
    @media screen and (max-width: 767px) {
      width: 100%;
      + button {
        margin-top: 15px;
      }
    }
    @media screen and (min-width: 768px) {
      display: inline-block;
      border: 0;
      padding: 0;
      border-bottom: 1px dashed $indigo;
      background: transparent;
      border-radius: 0;

      + button {
        margin-left: 15px;
      }
    }
  }

  a.select-button {
    background: $green;
    color: $white;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background ease-in-out 150ms;
    margin-top: 10px;
    padding: 10px;
    border-radius: 3px;

    &:hover {
      background: darken($green, 10%)
    }

    &.selected {
      background: $red;
      &:hover {
        background: darken($red, 10%)
      }
    }

    @media screen and (min-width: 768px) {
      position: absolute;
      bottom: 15px;
      right: 15px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin: 0;
      padding: 0;
    }
  }
</style>
